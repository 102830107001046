<template>
  <modal-layout id="spates-modal" size="lg">
    <div class="pre-modal--body spates py-0">
      <img class="" src="/assets/images/spates-1.png" alt="" />
      <div class="pre-pad"></div>
      <div class="pre-headline centered">How to Unearth a Hidden Spirit</div>
      <div class="pre-copy">
        <p>
          One often finds that spirits have a particular proclivity for
          concealing their whereabouts. Such a skill proves a pain for any
          inhabitants of a haunted environment; one cannot vanquish a ghost if
          one cannot find it. The following detailed explanation can be
          considered a comprehensive guide to locating, relocating and ridding
          oneself of spirits with peculiar persuasions pertaining to
          concealment, camouflage or cover.
        </p>
      </div>
      <div class="pre-pad"></div>
      <img class="" src="/assets/images/spates-2.png" alt="" />
      <div class="pre-pad"></div>
      <div class="pre-copy">
        <p>Step 1:</p>
      </div>
      <div class="pre-subhead">Tune your Environment</div>
      <div class="pre-copy">
        <p>
          Much like meditation, the process of tuning into one’s environment
          involves a broadening of awareness: sound, scent and sight. Before
          beginning the process of spirit location, one must make time to
          broaden one’s awareness to ensure sufficient sensitivity to
          psychokinetic disruptions.
        </p>
      </div>

      <div class="pre-pad"></div>
      <img class="" src="/assets/images/spates-2.png" alt="" />
      <div class="pre-pad"></div>
      <div class="pre-copy">
        <p>Step 2:</p>
      </div>
      <div class="pre-subhead">The Rule of Three</div>
      <div class="pre-copy">
        <p>
          The rule of three is well documented across subject matter and time.
          Just as the number seven holds powerful magical significance, so too
          does the number three. For reasons unknown to this writer, should one
          collect three completely unrelated and incongruous items, then unite
          them as if they belong together, the psychokinetic field surrounding
          such objects cannot help but respond, usually with a spike in a given
          sense. Stranded spirits hate this. Perhaps a strong smell, a loud
          sound, or a flare of sight. The convergence of these items, especially
          paired with other rules of three will reveal the location of a spirit.
          For example, should one unite these items at precisely three minutes
          past three, whilst blinking three times, a sensation should follow the
          process. Be it sight, smell or sound, one should investigate
          fervently, for this flare is sure to be a clue to the location of a
          hidden spirit.
        </p>
      </div>

      <div class="pre-pad"></div>
      <img class="" src="/assets/images/spates-2.png" alt="" />
      <div class="pre-pad"></div>
      <div class="pre-copy">
        <p>Step 3:</p>
      </div>
      <div class="pre-subhead">Rewilding</div>
      <div class="pre-copy">
        <p>
          Whilst the capture and destruction of spirits is not possible, one is
          able to re-wild a hidden spirit should one manage to deduct the object
          of attachment. By moving such an object to a new location, whilst
          whispering the following incantation under one’s breath three times,
          of course, this writer has found moderate success in relocating
          problem hidden spirits.
        </p>
        <p>Incantation: “novam domum manet te.”</p>
      </div>

      <div class="pre-pad"></div>
      <img class="" src="/assets/images/spates-2.png" alt="" />
      <div class="pre-pad"></div>
      <p>
        We hope that this process serves you well. Ensure you report any
        dislodged spirits to your peers and colleagues so that they can be
        catalogued and documented appropriately.
      </p>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "../ModalLayout.vue";
export default {
  components: { ModalLayout },
  name: "SpatesModal",
};
</script>
