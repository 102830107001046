<template>
  <modal-layout id="tobin-modal" size="lg">
    <div class="pre-modal--body tobin py-0 px-4">
      <div class="pre-cols">
        <div class="pre-col-1-2">
          <div class="pre-subhead">The Tongue Twister</div>
          <div class="pre-classify">
            <div class="pre-classify--row">
              <div class="pre-classify--label">Class:</div>
              <div class="pre-classify--value">V</div>
            </div>
            <div class="pre-classify--row">
              <div class="pre-classify--label">SUSPECTED ORIGIN LOCATION:</div>
              <div class="pre-classify--value">TIFFANY’S</div>
            </div>
            <div class="pre-classify--row">
              <div class="pre-classify--label">DESCRIPTION:</div>
              <div class="pre-classify--value">TIFFANY’S</div>
            </div>
          </div>
          <div class="pre-copy">
            <p>
              The Tongue Twister was spotted in New York's infamous diamond
              store, Tiffany's, by stunned shoppers following the shut down of a
              psychic containment unit on November 9th, 1984. Characterised by a
              huge nightmarish tongue, these Class V, semi-corporeal fleshy
              demonic balls are constantly reforming and shifting in shape due
              to their ectoplasmic nature. The Twister’ tongue prevents its
              structureless form from collapsing in on itself as a ball of
              slime. Those in close proximity to this Boogaloo type ghostcan
              become entranced by its unique mastication sound, whilst others
              can become overwhelmed with disgust.
            </p>
          </div>
        </div>
        <div class="pre-col-1-2">
          <img
            class="pre-image border-0"
            src="/assets/images/tobin.png"
            alt=""
          />
        </div>
      </div>
      <div class="pre-cols">
        <div class="pre-col-1-2">
          <img
            class="pre-image border-0"
            src="/assets/images/green-lady.png"
            alt=""
          />
        </div>
        <div class="pre-col-1-2">
          <div class="pre-subhead">The Green Lady</div>
          <div class="pre-classify">
            <div class="pre-classify--row">
              <div class="pre-classify--label">Class:</div>
              <div class="pre-classify--value">IV</div>
            </div>
            <div class="pre-classify--row">
              <div class="pre-classify--label">SUSPECTED ORIGIN LOCATION:</div>
              <div class="pre-classify--value">BROADWAY</div>
            </div>
            <div class="pre-classify--row">
              <div class="pre-classify--label">DESCRIPTION:</div>
              <div class="pre-classify--value">FULL-ROAMING APPARITION</div>
            </div>
          </div>
          <div class="pre-copy">
            <p>
              The Green Lady is another newly recognised broad classification,
              following the breach of a New York-based psychic containment
              device. These apparitions are fast moving and often take on the
              characteristics of an older woman, whose voice resembles a ghostly
              Broadway singer. They are difficult to see due to being almost
              translucent and upon attack, their mouth often opens to reveal a
              demonic appearance.
            </p>
          </div>
        </div>
      </div>
      <div class="pre-cols">
        <div class="pre-col-1-2">
          <div class="pre-subhead">The Gozerian Ghoul</div>
          <div class="pre-classify">
            <div class="pre-classify--row">
              <div class="pre-classify--label">Class:</div>
              <div class="pre-classify--value">III</div>
            </div>
            <div class="pre-classify--row">
              <div class="pre-classify--label">SUSPECTED ORIGIN LOCATION:</div>
              <div class="pre-classify--value">TRI-STATE AREA</div>
            </div>
            <div class="pre-classify--row">
              <div class="pre-classify--label">DESCRIPTION:</div>
              <div class="pre-classify--value">
                Corporeal. Full torso Dread. In demonic form: Class IV.
                Transmogrified Monstrous Form.
              </div>
            </div>
          </div>
          <div class="pre-copy">
            <p>
              These slow-moving ghosts can be considered a broad classification
              of spirit, fairly common during psychokinetic 'busy' seasons.
              Gozerian Ghouls are long-deceased Cult of Gozer members,
              presenting as Class 3 Corporeal, Full Torso ‘Dread’ type ghosts,
              shrouded in black wraith-like gowns and lacking lower appendages.
              Stimulation of any kind can provoke a drastic transmutation to
              their demonic form, categorised as a Class IV Transmogrified
              Monstrous Form. (In short, these apparitions are likely to
              frighten you but unlikely to cause much permanent harm). NB: more
              field data is required before this opinion can be verified.
            </p>
          </div>
        </div>
        <div class="pre-col-1-2">
          <img
            class="pre-image border-0"
            src="/assets/images/gozerian-ghoul.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "../ModalLayout.vue";
export default {
  components: { ModalLayout },
  name: "TobinModal",
};
</script>
<style lang="scss" scoped>
.pre-image {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
</style>
