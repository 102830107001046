<template>
  <modal-layout id="roylance-modal" size="lg">
    <div class="pre-modal--body roylance">
      <div class="pre-subhead caps">The Sebouillia</div>
      <div class="pre-copy">
        <p>
          The Sebouillia are an ancient pre-Sumerian society who vanished from
          the history books without a trace following a large-scale unknown
          event. They worshipped Gozer, an all-powerful deity who, according to
          the Glethestement, The Sebouillia’s primary religious text, would
          punish decadence and overindulgence. We can therefore conclude, based
          on the limited accounts we have, that The Sebouillia were wiped from
          existence by Gozer as punishment for their epicureanism.
        </p>
        <p>
          The Glethestement was written in Sanskrit, although much of the text
          has been lost to time. Translations appear to confirm that Gozer
          destroyed the Sebouillia for their sins. A previously discarded
          extract of the Glethestement was discovered in Ivo Shandor’s study.
          Shandor, founder of the Cult of Gozer, collected and studied ancient
          Sumerian and Sebouillian artefacts. The new extract now appears to
          predict the battle waged between Gozer and the Ghostbusters on the 9th
          November 1984. It reads as follows:
        </p>
        <p>
          “And the second coming of Gozer shall be so. For they shall face four
          warriors of light atop the world. The destructor, slain, shall be a
          colossus. Until once more the Gates of Gozer will open again.” - The
          Glethestement, Tablet 9
        </p>
      </div>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "../ModalLayout.vue";
export default {
  components: { ModalLayout },
  name: "RoylanceModal",
};
</script>
