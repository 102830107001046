<template>
  <div class="pre-body pre-body-md">
    <div class="pre-subhead pre-subhead-border centered">
      <div class="pre-divider"></div>
      <div class="pre-subhead--copy">Reading List</div>
      <div class="pre-divider"></div>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-cols align-top">
      <div class="pre-col-1-3">
        <lazy-img
          class="pre-image border-0"
          src="/assets/images/reading-1.png"
          alt=""
        />
      </div>

      <div class="pre-col-2-3">
        <div class="pre-headline">The Gates of Gozer</div>
        <div class="pre-subhead">By Dr. Rudolph Whitehead</div>
        <div class="pre-copy">
          <p>
            Dr Rudolph Whitehead is the most learned Gozarian expert in his
            field, a leading academic in pre-Sumerian artefacts and best-selling
            author of “The Gates of Gozer”. Dr Whitehead is joining the
            Paranormal Institute’s staff to provide special insight into the
            cultural relevance of 550 Central Park West, built by Ivo Shandor,
            founder of the Cult of Gozer. Having spent years studying ancient
            texts, Dr Whitehead has a unique perspective into the prophecies of
            ancient societies and what they mean for the modern American.
          </p>
        </div>
        <div class="pre-pad"></div>
        <div class="pre-btns">
          <pnw-button variant="outline-primary" v-b-modal.gozerian-modal>
            Read More
          </pnw-button>
          <gozerian-modal />
        </div>
      </div>
    </div>

    <div class="pre-pad"></div>
    <div class="pre-divider"></div>
    <div class="pre-pad"></div>

    <div class="pre-cols align-top">
      <div class="pre-col-1-3">
        <lazy-img
          class="pre-image border-0"
          src="/assets/images/reading-2.png"
          alt=""
        />
      </div>

      <div class="pre-col-2-3">
        <div class="pre-headline">Tobin’s Spirit Guide</div>
        <div class="pre-copy">
          <p>
            The following classifications are new additions to Tobin's Spirit
            Guide following the events of November 9th 1984 which led to a
            brief, sharp increase in sightings across New York City. No
            sightings have been reported since.
          </p>
        </div>
        <div class="pre-pad"></div>
        <div class="pre-btns">
          <pnw-button variant="outline-primary" v-b-modal.tobin-modal>
            Read More
          </pnw-button>
          <tobin-modal />
        </div>
      </div>
    </div>

    <div class="pre-pad"></div>
    <div class="pre-divider"></div>
    <div class="pre-pad"></div>

    <div class="pre-cols align-top">
      <div class="pre-col-1-3">
        <lazy-img
          class="pre-image border-0"
          src="/assets/images/reading-3.png"
          alt=""
        />
      </div>

      <div class="pre-col-2-3">
        <div class="pre-headline">The Roylance Guide</div>
        <div class="pre-subhead">
          The Sebouillia Predicts The Gates of Gozer
        </div>
        <div class="pre-copy">
          <p>
            The Roylance Guide to Secret Societies and Sects is a key part of
            the Paranormal Institute’s curriculum. This comprehensive guide to
            groups that worship supernatural, paranormal or celestial entities
            is critical background reading to aid understanding. New chilling
            evidence relating to the partial destruction of New York in 1984 has
            come to light. It seems our very realm may still be under threat.
            New interpretations of ancient extracts have been included in this
            limited edition.
          </p>
        </div>
        <div class="pre-pad"></div>
        <div class="pre-btns">
          <pnw-button variant="outline-primary" v-b-modal.roylance-modal>
            Read More
          </pnw-button>
          <roylance-modal />
        </div>
      </div>
    </div>

    <div class="pre-pad"></div>
    <div class="pre-divider"></div>
    <div class="pre-pad"></div>

    <div class="pre-cols align-top">
      <div class="pre-col-1-3">
        <lazy-img
          class="pre-image border-0"
          src="/assets/images/reading-4.png"
          alt=""
        />
      </div>

      <div class="pre-col-2-3">
        <div class="pre-headline">Spates Catalogue</div>
        <div class="pre-subhead">How to Unearth a Hidden Spirit</div>
        <div class="pre-copy">
          <p>
            Spates Catalog was a yearly periodical written by T. Watson Spates.
            A compendium of nameless horrors, the literature covers a spectrum
            of useful information pertaining to ghosts, geists, spirits and the
            occult. Particularly useful for the Ghostbuster-in-training is
            Spates’ article from their 1935 final edition, ‘How to Unearth a
            Hidden Spirit’.
          </p>
        </div>
        <div class="pre-pad"></div>
        <div class="pre-btns">
          <pnw-button variant="outline-primary" v-b-modal.spates-modal>
            Read More
          </pnw-button>
          <spates-modal />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PnwButton from "../components/PnwButton.vue";
import GozerianModal from "../components/ReadlingListModal/GozerianModal.vue";
import RoylanceModal from "../components/ReadlingListModal/RoylanceModal.vue";
import SpatesModal from "../components/ReadlingListModal/SpatesModal.vue";
import TobinModal from "../components/ReadlingListModal/TobinModal.vue";
export default {
  components: {
    TobinModal,
    RoylanceModal,
    SpatesModal,
    GozerianModal,
    PnwButton,
  },
  name: "Readings",
  data() {
    return {
      readings: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 800px;
}
.pre-image {
  max-width: 312px !important;
}
</style>
