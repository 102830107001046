<template>
  <modal-layout id="gozerian-modal" size="lg">
    <div class="pre-modal--body gozerian py-0">
      <div class="pre-hashhead">
        <img
          class="pre-hashead--img flipped"
          src="/assets/images/corner.png"
          alt=""
        />
        <div class="pre-heashhead--copy">EXCERPT FROM “THE GATES OF GOZER”</div>
      </div>
      <div class="pre-subhead">
        When it comes to Gozerian prophecy, much can be derived from the
        metaphors within the old texts.
      </div>
      <div class="pre-copy">
        <p>
          Of course, there are lessons for the modern American within the
          Sebouillian tablets as the ancient civilisations hold wisdom lost to
          time. As an academic in this field, I have spent much of my career
          recovering the lost texts and relaying their benefits to the public.
          Recent transcripts of newly discovered portions of the Glethestement,
          The Sebouillian’s religious text, reveal exciting developments for
          this field of study and beyond. It seems that our society does not
          learn from the past. Indeed, we tend to repeat the mistakes of our
          forebears.
        </p>
        <p>
          Followers of Gozer have cautioned against decadence and
          self-indulgence for centuries, nay, millennia but their warnings have
          fallen upon deaf ears. With a society too intent on ignoring that
          which is right in front of them, is it truly worth saving? Where
          energy meets opportunity, the meridian of truth shall be revealed.
        </p>
        <p>
          Throughout this book, I shall explore the Gozerian principles of
          living and the rules by which we should abide. I will argue that you
          *can* win the Gozerian way should you choose to. But the actions of
          one do not discount the actions of many. The prophecies of the past
          may yet still come to light. I, for one, hope they do.
        </p>
      </div>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "../ModalLayout.vue";
export default {
  components: { ModalLayout },
  name: "GozerianModal",
};
</script>
